// extracted by mini-css-extract-plugin
export var content = "sidebar-module--content--uhAF4";
export var sidebarContent = "sidebar-module--sidebarContent--1QQmD";
export var profileAvatar = "sidebar-module--profileAvatar--2O9cE";
export var name = "sidebar-module--name--1LoqA";
export var badge = "sidebar-module--badge--1IIp8";
export var badgeGray = "sidebar-module--badgeGray--17KuS";
export var boxName = "sidebar-module--boxName--32ohV";
export var contactBlock = "sidebar-module--contactBlock--3rPaK";
export var contactBlockItem = "sidebar-module--contactBlockItem--2ANc6";
export var resumeDownload = "sidebar-module--resumeDownload---gi0g";
export var emailHider = "sidebar-module--emailHider--2hBY9";
export var background = "sidebar-module--background--3uP7q";
export var boxContent = "sidebar-module--boxContent--3KmNf";
export var sideBar404Radius = "sidebar-module--sideBar404Radius--3ijS-";